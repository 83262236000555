import { useApp, usePageTheme } from '@Src/context/AppContext';
import { PageThemeProvider, ContentRefreshProvider } from '@Context/AppContext';
import { graphql, useStaticQuery } from 'gatsby';
import React, { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import Footer from '@Src/components/nav/Footer';
import Navigation from '@Components/nav/Navigation';
import CookieConsent from '@Components/CookieConsent';
import '@GraphQL/fragments';

import TitleBar from '@Components/nav/TitleBar';
import ArtArea from '@Components/ArtArea';
import Art_01 from '@Components/three/scenes/Art_01';
import { IPageProps } from '@Src/types/ContentfulTypes';
import HTMLBackground from '@Components/sitestructure/HTMLBackground';
import Root from '@Components/sitestructure/Root';
import Content from '@Components/sitestructure/Content';
import cx from 'classnames';
import LogoBanner from '@Src/components/LogoBanner';
import * as styles from './index.module.scss';
export interface Props extends IPageProps {
	/**
	 * Children to render
	 */
	children: ReactNode | Array<ReactNode>;
}

const Layout = ({ location, children, pageContext }: Props): ReactElement => {
	const { theme: pageTheme } = usePageTheme();
	const [isHome, setIsHome] = useState(true);
	const { pageType: initialPageType, pageTitle, mode, loaded } = useApp();
	const [pageReady, setPageReady] = useState(false);
	const currentTheme = useRef(pageTheme);
	const pageType = pageContext.type ? pageContext.type : 'index';
	const data = useStaticQuery(graphql`
		query Navigation {
			contentfulUiMenu {
				items {
					... on Link {
						...LinkFields
					}
				}
			}
			contentfulUiFooter {
				links {
					... on Link {
						...LinkFields
					}
				}
			}
		}
	`);
	const { items: menuItems } = data.contentfulUiMenu;
	const { links: footerLinks } = data.contentfulUiFooter;
	useEffect(() => {
		setIsHome(/*pageType === 'index' */ location.pathname === '/' ? true : false);
	}, [pageType]);

	useEffect(() => {
		isHome ? document.body.classList.add('index') : document.body.classList.remove('index');
	}, [isHome]);

	useEffect(() => {
		document.body.classList.remove(currentTheme.current);
		document.body.classList.add(pageTheme);
		currentTheme.current = pageTheme;
	}, [pageTheme]);

	useEffect(() => {
		if (mode != 'unset' && loaded) {
			setTimeout(() => setPageReady(true), isHome ? 0 : 10);
		}
	}, [mode, loaded]);

	if (location.pathname === '/') {
		return (
			<PageThemeProvider initialTheme={pageTheme}>
				<Root pageReady={pageReady}>
					<ArtArea active={isHome} visible={pageReady}>
						<Art_01 active={isHome} className={cx(styles[pageType], styles.webGLScene)} />
					</ArtArea>
					<TitleBar pageTitle={pageTitle} visible={false} />
					<ContentRefreshProvider>
						<Content location={location} visible={pageReady}>
							{children}
						</Content>
					</ContentRefreshProvider>
					<LogoBanner visible={(pageType === 'hero' || pageType === 'signup') && pageReady}></LogoBanner>
					<Navigation items={menuItems} footerLinks={footerLinks} isHome={isHome} visible={true} />
					<Footer links={footerLinks} visible={true} />
					<CookieConsent />
				</Root>
			</PageThemeProvider>
		)
	}
	console.log("pageType", pageType)
	return (
		<PageThemeProvider initialTheme={pageTheme}>
			<Root pageReady={pageReady}>
				{isHome &&
					<ArtArea active={isHome} visible={pageReady}>
						<Art_01 active={isHome} className={cx(styles[pageType], styles.webGLScene)} />
					</ArtArea>}
				<TitleBar pageTitle={pageTitle} visible={!isHome && pageReady && pageType !== 'advanceInquiry' && pageType !== 'campaign'} />
				<ContentRefreshProvider>
					<Content location={location} visible={pageReady}>
						{children}
					</Content>
				</ContentRefreshProvider>
				<LogoBanner visible={(pageType === 'hero' || pageType === 'signup' || pageType === 'campaign' || location.pathname === '/advance' || location.pathname === '/advance2' ) && pageReady}></LogoBanner>
				<Navigation items={menuItems} footerLinks={footerLinks} isHome={isHome} visible={pageReady} />
				<Footer links={footerLinks} visible={pageReady} />
				<CookieConsent />
			</Root>
		</PageThemeProvider>
	);
};
export default Layout;

/* <HTMLBackground active={!isHome} /> */
