import React, { ReactElement, useState, useEffect, createContext, useContext } from 'react';
import cx from 'classnames';
import Link from '@UI/Link';
import { useApp, usePageTheme } from '@Src/context/AppContext';
import { UiHeading01 } from '@Components/Text';
import { ILink } from '@Src/types/ContentfulTypes';
import * as styles from './index.module.scss';


/////////////////////////////////////////////////////////////////////
// NavItem Context
////////////////////////////////////////////////////////////////////

export type NavItemContextType = {
	active: boolean;
	setActive: (active: boolean) => void;
};

const NavItemContext = createContext<NavItemContextType>({ active: false, setActive: () => {} });
export const useNavItemContext = (): NavItemContextType => useContext(NavItemContext);

/////////////////////////////////////////////////////////////////////////////////////////////
// NAV ITEM
/////////////////////////////////////////////////////////////////////////////////////////////
export interface INavItem extends ILink {
	id: string;
	theme?: string;
	onTrigger?: () => void;
	triggerDelay?: number;
}

export const NavItem = ({
	id,
	label,
	url,
	theme,
	target = '_self',
	onTrigger,
	triggerDelay
}: INavItem): ReactElement => {
	const [active, setActive] = useState(false);

	return (
		<NavItemContext.Provider value={{ active, setActive }}>
			{/* eslint-disable  */}
			<div className={cx(styles.item, active && styles.active)}>
				<Link
					id={id}
					url={url}
					className={cx(styles.link)}
					activeClassName={styles.active}
					target={target}
					onTrigger={onTrigger}
					triggerDelay={triggerDelay}
				>
					<UiHeading01 theme={theme} text={label} className={styles.label} />
				</Link>
			</div>
		</NavItemContext.Provider>
	);
};

/////////////////////////////////////////////////////////////////////////////////////////////
// NAVIGAITON
/////////////////////////////////////////////////////////////////////////////////////////////

export interface Props {
	/**
	 * Navigation items to be displayed.
	 */
	items: INavItem[];

	/**
	 * Navigation items from footer, displays on mobile menu
	 */
	footerLinks?: INavItem[];

	visible: boolean;
	isHome: boolean;
}

const Navigation = ({ items, footerLinks, visible, isHome }: Props): ReactElement => {
	const { theme: navTheme } = usePageTheme();
	const [menuOpen, setMenuOpen] = useState(false);
	const { pageType } = useApp();

	/*useEffect(() => {
		setIsHome(pageType == 'index');
	}, [pageType]);*/

	return (
		<nav
			className={cx(
				isHome && styles.isHome,
				styles.root,
				navTheme && styles[navTheme],
				menuOpen && styles.top,
				visible && styles.visible,
				styles[pageType]
			)}
		>
			<div className={cx(styles.inner)}>
				<div className={cx(styles.menu, menuOpen && styles.menuOpen)}>
					{items.map(
						(item: INavItem): ReactElement => (
							<NavItem
								theme={navTheme}
								key={item.id}
								{...item}
								id={item.id}
								triggerDelay={menuOpen ? 0.25 : 0}
								onTrigger={() => {
									setMenuOpen(false);
								}}
							/>
						)
					)}

					<div className={styles.footerLinks}>
						{footerLinks &&
							footerLinks.map(
								(item: INavItem): ReactElement => (
									<NavItem
										theme={navTheme}
										key={item.id}
										{...item}
										id={item.id}
										triggerDelay={0.25}
										onTrigger={() => {
											setMenuOpen(false);
										}}
									/>
								)
							)}
					</div>
				</div>

				<div className={cx(isHome && styles.isHome, styles[navTheme], styles.mobileMenu, menuOpen && styles.open)}>
					<Link
						id="signup"
						url="/signup"
						label=""
						className={styles.signupCTA}
						onTrigger={() => {
							setMenuOpen(false);
						}}
					>
						<UiHeading01 className={cx(styles.title)} theme={navTheme} text={'Get Started'}></UiHeading01>
					</Link>
					<div className={styles.menuIcon}>
						<div className={cx(styles.gfx)} />
						<button type="button" className={cx(styles.toggle)} onClick={() => setMenuOpen(!menuOpen)} />
					</div>
				</div>
			</div>
		</nav>
	);
};

export default Navigation;
