import React, { ReactElement } from 'react';
import * as styles from './index.module.scss';

export interface Props {
	size?: number;
}

export default function Loader({ size = 4 }: Props): ReactElement<Props> {
	return <div style={{ fontSize: size }} className={styles.loader}></div>;
}
