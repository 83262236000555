import { useEffect, useState, useRef } from 'react';
export interface MousePos {
	x: number;
	y: number;
	isDown: number;
}

export interface Props {
	scale: number;
}
export const useMousePosition = ({ scale }: Props) => {
	const [position] = useState<MousePos>({ x: 0, y: 0, isDown: 0 });
	const stateRef = useRef(position);
	const inactiveTimer = useRef<any>();
	useEffect(() => {
		const hasTouchEvents = 'ontouchstart' in window;
		const hasPointerEvents = window.PointerEvent;
		const hasTouch =
			hasTouchEvents || (window.DocumentTouch && document instanceof DocumentTouch) || navigator.maxTouchPoints; // IE >=11

		const pointerDown = 'touchstart';
		const pointerUp = 'touchend';
		const pointerMove = hasTouch ? 'touchmove' : 'mousemove';
		const handleMouseMove = (e: any) => {
			if (e.clientX) {
				stateRef.current.x = e.clientX * scale;
				stateRef.current.y = e.clientY * scale;
				position.isDown = 1;
			} else if (e.touches) {
				stateRef.current.x = e.touches[0].clientX * scale;
				stateRef.current.y = e.touches[0].clientY * scale;
			}
		};

		window.addEventListener(pointerMove, handleMouseMove, { passive: false });
		const handleIsDown = (e: TouchEvent) => {
			clearTimeout(inactiveTimer.current);
			stateRef.current.x = e.touches[0].clientX * scale;
			stateRef.current.y = e.touches[0].clientY * scale;
			stateRef.current.isDown = 1;
		};

		window.addEventListener(pointerDown, handleIsDown);

		const handleIsUp = () => {
			inactiveTimer.current = setTimeout(() => {
				stateRef.current.isDown = 0;
			}, 3000);
			return () => {
				clearTimeout(inactiveTimer.current);
			};
		};
		window.addEventListener(pointerUp, handleIsUp);

		return () => {
			window.removeEventListener(pointerMove, handleMouseMove);
			window.removeEventListener(pointerDown, handleIsDown);
			window.removeEventListener(pointerUp, handleIsUp);
		};
	}, [stateRef]);

	return stateRef;
};
