import React, { ReactElement, ReactNode } from 'react';
import cx from 'classnames';
import * as styles from './index.module.scss';

export interface Props {
	children: ReactNode | Array<ReactNode>;
	location: any;
	visible: boolean;
}

const Content = ({ children, location, visible }: Props): ReactElement => {
	return (
		<main id={styles.content} className={cx(visible && styles.visible)}>
			{children}
		</main>
	);
};

export default Content;
