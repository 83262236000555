import { useMousePosition } from '@Src/utils/useMousePosition';
import { useWindowResize } from '@Src/utils/useWindowResize';
import React, { useEffect, createRef, useRef } from 'react';
import {
	OrthographicCamera,
	WebGLRenderer,
	Color,
	Vector2,
	Vector3,
	Clock,
	Texture,
	TextureLoader,
	LinearFilter,
	NearestFilter,
	LinearMipMapLinearFilter
} from 'three';

import Stats from 'stats.js';
import { ReactionDiffusionPass } from '../passes/ReactionDiffusionPass';
import { PostFXPass } from '../passes/PostFXPass';
import { useApp, usePageTheme } from '@Src/context/AppContext';
import cx from 'classnames';
import { LogoTexturePass } from '../passes/LogoTexturePass';
import * as styles from './index.module.scss';
import { CAPass } from '../passes/CAPass';
const createDefaultCamera = () => {
	const camera = new OrthographicCamera(-1, 1, 1, -1, 0, 1);
	return camera;
};

const createDefaultRenderer = () => {
	const renderer = new WebGLRenderer({
		antialias: false,
		precision: 'lowp'
	});

	renderer.autoClear = true;
	renderer.setClearAlpha(1);
	renderer.setClearColor(new Color(0, 0, 0));
	return renderer;
};

export interface Props {
	/**
	 * Classname to be merged
	 */
	className?: string;
	active: boolean;
}

const Art_01 = ({ active, className }: Props) => {
	const { theme } = usePageTheme();
	const { mode, useFallback, setUseFallback, setLoaded, loaded, pageType } = useApp();
	const scaleRef = useRef<number>(0.25);
	const windowSize = useWindowResize();
	const mountRef = createRef<HTMLDivElement>();
	const webGlContainerRef = createRef<HTMLDivElement>();
	const logoSvgRef = useRef<SVGSVGElement>();

	const mousePosRef = useMousePosition({ scale: scaleRef.current });
	const windowSizeRef = useRef(windowSize);
	const requestRef = useRef<number>(0);
	const stats = new Stats();
	stats.showPanel(0);
	const statsRef = useRef(stats);

	//RENDERER
	const renderRef = useRef<WebGLRenderer>();
	const cameraRefDefault = useRef<OrthographicCamera>();

	//INITIALIZE PASSES
	const rdPass_logo = useRef<LogoTexturePass>();
	const rdPass_compute = useRef<ReactionDiffusionPass>();
	const rdPass_post = useRef<PostFXPass>();
	const rdPass_ca = useRef<CAPass>();
	const logoTexture = useRef<Texture>();
	const pipedTexture = useRef<Texture>();
	const clock = useRef<Clock>();
	const mounted = useRef<boolean>(false);
	const autoHover = useRef<number>(0);

	mousePosRef.current.x = 0;
	mousePosRef.current.y = 0;
	useEffect(() => {
		//used for chaining renders

		let outTexture: Texture | undefined = new Texture();

		let lastRender: number;
		let elapsed: number;
		let fpsIntervalCompute: number;

		//MOUNT ALL
		if (!mounted.current && webGlContainerRef.current) {
			pipedTexture.current = new Texture();
			clock.current = new Clock();
			lastRender = clock.current.elapsedTime;
			elapsed = 0;
			fpsIntervalCompute = 1000 / 60;

			renderRef.current = createDefaultRenderer();
			// FALLBACK DETECTION
			const maxP = renderRef.current.capabilities.getMaxPrecision('highp');
			if (maxP !== 'highp' || !window.WebGLRenderingContext) {
				setUseFallback(true);
				setLoaded(true);
				return;
			}
			cameraRefDefault.current = createDefaultCamera();

			rdPass_logo.current = new LogoTexturePass(new Vector2(2048, 2048));
			const loader = new TextureLoader();
			loader.load('/textures/logo.png', (t) => {
				setLoaded(true);
				t.needsUpdate = true;
				t.minFilter = LinearMipMapLinearFilter;
				t.magFilter = LinearMipMapLinearFilter;
				rdPass_logo.current?.update({ sTexture: t });
				if (renderRef.current && cameraRefDefault.current)
					logoTexture.current = rdPass_logo.current?.render(renderRef.current, cameraRefDefault.current);

				if (logoTexture.current) {
					logoTexture.current.minFilter = LinearFilter;
					logoTexture.current.magFilter = LinearFilter;
				}
			});
			rdPass_compute.current = new ReactionDiffusionPass(new Vector2(0, 0), false);
			rdPass_post.current = new PostFXPass(new Vector2(0, 0), 1);
			rdPass_ca.current = new CAPass();

			webGlContainerRef.current.appendChild(renderRef.current.domElement);
			//webGlContainerRef.current.appendChild(statsRef.current.dom);
			renderLayout(true);
			mounted.current = true;
		}

		const render = () => {
			if (!renderRef.current || !cameraRefDefault.current) return;
			statsRef.current.begin();
			const delta = clock.current?.getDelta();
			const now = Date.now();
			elapsed = now - lastRender;
			if (elapsed < fpsIntervalCompute) return;
			lastRender = now - (elapsed % fpsIntervalCompute);
			const resTrue = new Vector2(
				Math.ceil(windowSizeRef.current.width / (scaleRef.current * windowSizeRef.current.dpi)),
				Math.ceil(windowSizeRef.current.height / (scaleRef.current * windowSizeRef.current.dpi))
			);
			const unset = mousePosRef.current.x == 0 && mousePosRef.current.y == 0 ? true : false;
			const offsetY = resTrue.width < 992 ? 0.3 : 0.8;

			if (mousePosRef.current.isDown) autoHover.current = 0;
			else autoHover.current += (1 - autoHover.current) * 0.01;
			////////////////// MOUSE //////////////////////////////////////////////
			const mp = new Vector3(
				!unset ? mousePosRef.current.x : windowSizeRef.current.width * 0.5 * windowSizeRef.current.dpi,
				!unset
					? windowSizeRef.current.height - mousePosRef.current.y * windowSizeRef.current.dpi
					: windowSizeRef.current.height * offsetY * windowSizeRef.current.dpi,
				autoHover.current
			);

			rdPass_compute.current?.update({
				time: clock.current?.elapsedTime,
				brush: mp,
				sTrail: pipedTexture.current //IMPORTANT, NEED TO SET PREV
			});
			pipedTexture.current = rdPass_compute.current?.render(renderRef.current, cameraRefDefault.current);

			rdPass_post.current?.update({
				sLogo: logoTexture.current,
				colorBuffer: pipedTexture.current,
				logoPos: resTrue.width < 992 ? 1 : 0,
				time: clock.current?.elapsedTime,
				brush: mp
			});

			outTexture = rdPass_post.current?.render(renderRef.current, cameraRefDefault.current);

			rdPass_ca.current?.update({
				sTexture: outTexture,
				time: clock.current?.elapsedTime
			});

			/////////////////////// FINAL RENDER TO STAGE /////////////////////////////////////
			renderRef.current.setRenderTarget(null);
			rdPass_ca.current && renderRef.current.render(rdPass_ca.current.scene, cameraRefDefault.current);
			//}

			statsRef.current.end();
		};

    if (active) {
      const animate = () => {
        if (active) {
          requestRef.current = requestAnimationFrame(animate);
          render();
        }
      };
      requestRef.current = requestAnimationFrame(animate);
    }
		return () => cancelAnimationFrame(requestRef.current);
	}, [active]);

	useEffect(() => {
		renderLayout();
	}, [windowSize]);

	useEffect(() => {
		rdPass_post.current?.update({ mode: mode == 'night' ? 0 : mode == 'day' ? 1 : 2 });
		rdPass_logo.current?.update({
			outline: mode == 'night' ? 0.0 : 1.0
		});

		if (renderRef.current && cameraRefDefault.current)
			logoTexture.current = rdPass_logo.current?.render(renderRef.current, cameraRefDefault.current);

		if (logoTexture.current) {
			logoTexture.current.minFilter = LinearFilter;
			logoTexture.current.magFilter = LinearFilter;
		}
	}, [mode]);

	const renderLayout = (force: boolean = false) => {
		if (!mountRef.current || windowSize.height == 0) return;

		const res = new Vector2(
			Math.ceil(windowSize.width * scaleRef.current * windowSizeRef.current.dpi),
			Math.ceil(windowSize.height * scaleRef.current * windowSizeRef.current.dpi)
		);

		const resTrue = new Vector2(
			res.x / (scaleRef.current * windowSizeRef.current.dpi),
			res.y / (scaleRef.current * windowSizeRef.current.dpi)
		);

		if (!force && windowSizeRef.current.width == res.x && windowSizeRef.current.height == res.y) return;
		windowSizeRef.current.width = res.x;
		windowSizeRef.current.height = res.y;

		renderRef.current?.setSize(resTrue.width, resTrue.height, true);
		renderRef.current?.setPixelRatio(windowSizeRef.current.dpi);

		rdPass_compute.current?.resize(res);

		rdPass_post.current?.resize(resTrue);
		rdPass_ca.current?.resize(resTrue);

		console.log('RESIZE CANVAS');
	};

	return (
		<div
			className={cx(styles[theme], active && styles.active, styles.root, loaded && styles.loaded, className)}
			ref={mountRef}
		>
			{!useFallback && <div className={styles.webGL} ref={webGlContainerRef}></div>}
			<div className={cx(styles[theme], styles[pageType], styles.logo)}>
				<svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 743.91 100.3">
					<g id="Lager_1">
						<path d="M67.74,57.63H32.16L49.75,16.15l17.99,41.48h0Zm17.07,39.39h15.88L58.29,3.29h-15.89L0,97.02H14.97l11.95-27.31h45.95l11.94,27.31h0Zm23.1,0h94.39v-25.21h-57.37V3.28h-37.02V97.02h0Zm97.61-46.87c0,48.57,41.62,50.15,64.07,50.15s64.06-1.58,64.06-50.15S292.03,0,269.58,0s-64.06,1.58-64.06,50.15h0Zm39.38,0c0-19.04,9.58-26.52,24.68-26.52s24.68,7.48,24.68,26.52-9.58,26.52-24.68,26.52c-15.09,0-24.68-7.48-24.68-26.52h0Zm157.79,13.26h-22.32l11.03-31.77h.26l11.03,31.77h0Zm-73.78,33.61h39.39l3.94-10.77h38.6l3.94,10.77h39.39L414.26,3.29h-45.42l-39.93,93.73h0ZM495.79,26.91h21.27c16.94,0,23.76,8.4,23.76,23.24s-6.83,23.24-23.76,23.24h-21.27V26.91h0Zm-37.02,70.11h72.86c31.11,0,47.79-13.92,47.79-46.87s-16.8-46.87-48.57-46.87h-72.07V97.02h0Zm129.44,0h55.66v-17.85h-33.61v-21.4h30.19v-17.85h-30.19V21.15h32.16V3.3h-54.22V97.02h.01Zm67.22,0h48.31c28.75,0,40.17-22.05,40.17-48.18s-12.87-45.55-40.96-45.55h-47.52V97.02h0Zm13.91-12.08V15.36h31.11c24.16,0,29.54,17.2,29.54,33.74s-4.07,35.84-26.91,35.84h-33.74Z" />
					</g>
				</svg>
			</div>
		</div>
	);
};
export default Art_01;

/*	<svg ref={logoSvgRef} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1002.47 136.22">
					<path d="M618.16,5.32h3.74c31.55,0,63.1-.24,94.65,.15,8.91,.11,18,1.27,26.66,3.38,18.01,4.39,29.49,16.08,34.06,34.05,4.12,16.22,4.26,32.61,.24,48.86-5.22,21.09-19.29,33.14-40.35,37.11-7.23,1.36-14.69,1.99-22.05,2.04-31.32,.22-62.64,.09-93.96,.09-.89,0-1.79-.09-2.97-.16V5.32Zm49.74,94.32c12.98-.43,25.65-.13,38.14-1.46,12.26-1.3,19.56-9.15,21.31-21.4,.78-5.43,.85-11.13,.13-16.57-1.74-13.18-9.46-21.18-22.74-22.33-11.98-1.04-24.09-.51-36.14-.66-.18,0-.37,.22-.71,.43v61.98Z" />
					<path d="M363.74,135.2c-15.07,.09-30.06-.6-44.58-5.2-23.2-7.35-36.76-23-40.49-46.97-1.74-11.19-1.64-22.4,.67-33.51,5.02-24.13,20.43-38.2,43.8-44.4,15.92-4.23,32.21-4.3,48.51-4.02,12.45,.21,24.77,1.42,36.72,5.2,23.07,7.3,36.62,22.81,40.43,46.63,1.81,11.3,1.71,22.63-.61,33.84-4.71,22.75-18.81,36.89-40.85,43.56-11.65,3.53-23.65,4.63-35.75,4.86-2.62,.05-5.24,0-7.86,0Zm-33.65-67.4l.53,.12c.13,2.49,.16,4.99,.42,7.46,1.49,14.46,8.84,23.75,21.26,26.86,6.6,1.65,13.3,1.84,19.98,.63,13.08-2.37,20.73-9.84,23.46-22.84,1.65-7.86,1.66-15.78,.03-23.64-2.39-11.53-8.87-19.49-20.68-22.38-7.41-1.81-14.91-1.82-22.33-.07-9.98,2.36-16.88,8.38-19.74,18.25-1.47,5.06-1.98,10.4-2.92,15.61Z" />
					<path d="M611.79,131.01c-5.15,0-9.8,0-14.45,0-11.73,0-23.46-.08-35.18,.06-2.62,.03-4-.72-4.58-3.28-.37-1.64-1.15-3.2-1.74-4.79q-2.37-6.46-9.44-6.46c-13.89,0-27.78,.05-41.67-.05-2.31-.02-3.52,.62-4.19,2.88-.94,3.15-2.06,6.26-3.44,9.23-.48,1.04-1.96,2.29-3,2.3-16.4,.14-32.79,.09-49.19,.07-.33,0-.65-.13-1.4-.28,.39-1.1,.7-2.13,1.12-3.12,16.99-39.74,34.01-79.47,50.96-119.22,.91-2.13,1.92-3.11,4.45-3.09,18.33,.13,36.66,.1,55,.02,2.22,0,3.41,.54,4.34,2.73,17.06,40.08,34.22,80.12,51.36,120.17,.31,.71,.54,1.46,1.04,2.84Zm-69.39-45.42c-4.91-14.07-9.7-27.78-14.5-41.5l-.72,.08c-4.78,13.72-9.56,27.43-14.42,41.41h29.64Z" />
					<path d="M196.25,97.08h76.97v33.64h-126.58V5.61h49.61V97.08Z" />
					<path d="M791.88,130.78V5.6h72.74V29.43h-42.87v24.81h40.09v24.1h-40.04v28.57h44.74v23.88h-74.67Z" />
					<path d="M882.22,130.6V5.5c1.31-.07,2.51-.18,3.7-.18,20.27-.01,40.55-.17,60.82,.09,5.75,.07,11.64,.8,17.21,2.21,17.53,4.44,28.61,15.96,33.62,32.97,5.64,19.14,5.18,38.39-1.53,57.21-7.37,20.68-23.46,32.49-45.5,32.95-22.2,.46-44.41,.1-66.62,.1-.44,0-.88-.13-1.7-.25Zm18.8-16.12c.73,.12,1.16,.25,1.59,.25,15.25-.02,30.51,.25,45.75-.16,16.19-.43,26.6-8.79,31.04-24.3,3.52-12.3,3.88-24.86,1.76-37.4-2.28-13.46-8.59-24.23-22.39-28.74-4.68-1.53-9.79-2.31-14.72-2.43-13.31-.33-26.63-.12-39.95-.1-.99,0-1.97,.14-3.09,.23V114.48Z" />
					<path d="M1.47,131.01c.58-1.47,.93-2.5,1.38-3.49C20.87,87.85,38.93,48.2,56.87,8.51c1.11-2.45,2.37-3.38,5.08-3.26,5.11,.23,10.25,.14,15.37,.03,1.85-.04,2.83,.52,3.62,2.26,18.31,40.4,36.69,80.77,55.04,121.16,.27,.59,.41,1.23,.76,2.28-6.94,0-13.5,.09-20.06-.11-.81-.02-1.85-1.47-2.3-2.47-4.63-10.35-9.24-20.72-13.67-31.16-.96-2.27-2.2-2.92-4.58-2.91-18.45,.09-36.89,.11-55.34-.01-2.49-.02-3.57,.86-4.49,3.03-4.41,10.33-9.05,20.56-13.46,30.89-.9,2.1-1.98,2.91-4.3,2.83-5.54-.17-11.09-.06-17.08-.06ZM68.35,22.82c-8.02,18.85-15.67,36.83-23.45,55.12h47.45c-7.96-18.28-15.76-36.21-23.99-55.12Z" />
				</svg>*/
