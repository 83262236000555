type Callback = () => void;

const listenTransitionEnd = (element: HTMLElement, callback?: Callback): void => {
	const transitionEnd = () => {
		if (callback) {
			callback();
			element.removeEventListener('transitionend', transitionEnd);
		}
	};

	element.addEventListener('transitionend', transitionEnd);
};

export default listenTransitionEnd;
