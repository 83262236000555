import {
	RGBAFormat,
	Texture,
	Vector2,
	WebGLRenderer,
	WebGLRenderTarget,
	UnsignedByteType,
	ClampToEdgeWrapping,
	NearestFilter,
	LinearFilter
} from 'three';

interface IBuffer {
	target: WebGLRenderTarget;
	needsResize: boolean;
}

export class RenderTarget {
	private index: number;
	private buffers: IBuffer[];
	public res: Vector2;
	constructor(
		readonly resolution: Vector2,
		readonly nBuffers: number = 1,
		readonly type: number = UnsignedByteType,
		readonly format: number = RGBAFormat,
		readonly wrapS: number = ClampToEdgeWrapping,
		readonly wrapT: number = ClampToEdgeWrapping,
		readonly magFilter: number = LinearFilter,
		readonly minFilter: number = LinearFilter
	) {
		this.index = 0;
		this.res = new Vector2(resolution.x, resolution.y);
		this.buffers = [
			{
				target: new WebGLRenderTarget(this.res.x, this.res.y, {
					type,
					format,
					wrapS,
					wrapT,
					depthBuffer: false,
					stencilBuffer: false,
					magFilter,
					minFilter
				}),
				needsResize: false
			}
		];
		for (let i = 1; i < nBuffers; ++i) {
			this.buffers[i] = {
				target: this.buffers[0].target.clone(),
				needsResize: false
			};
		}
	}

	public resize(resolution: Vector2): void {
		this.res.copy(resolution);
		for (let i = 0; i < this.nBuffers; ++i) {
			this.buffers[i].needsResize = true;
		}
	}

	public get(): Texture {
		return this.buffers[this.index].target.texture;
	}

	public set(renderer: WebGLRenderer): Texture {
		const buffer = this.buffers[this.index++];
		if (buffer.needsResize) {
			buffer.needsResize = false;
			buffer.target.setSize(this.res.x, this.res.y);
		}
		renderer.setRenderTarget(buffer.target);
		this.index %= this.nBuffers;
		return buffer.target.texture;
	}
}
