import React, { ReactElement, useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import BaseButton from '@UI/BaseButton';
import { useCookies } from 'react-cookie';
import Link from '@UI/Link';
import { Body } from '@Components/Text';
import listenTransitions from '@Src/utils/listenTransition';
import { useApp, usePageTheme } from '@Src/context/AppContext';
import * as styles from './index.module.scss';
const CookieConsent = (): ReactElement => {
	const TRACKING_ID = process.env.GA_TRACKING_ID;
	const gaDisableString = 'ga-disable-' + TRACKING_ID;
	const cookieName = 'ga-disable-' + TRACKING_ID + '1';
	const debug = false;
	const [cookies, setCookie, removeCookie] = useCookies([cookieName]);
	const initAppear = debug || cookies[cookieName] === undefined;
	const [appear, setAppear] = useState(initAppear);
	const ref = useRef<HTMLDivElement>(null);
	const [open, setOpen] = useState(false);
	const { theme } = usePageTheme();
	const { pageType, setCookieConsentOpen } = useApp();

	useEffect(() => {
		let appearTimer = setTimeout(() => {}, 0);
		if (ref.current && !open) {
			appearTimer = setTimeout(() => setOpen(true), 3000);
		}
		return () => {
			clearTimeout(appearTimer);
		};
	}, [ref]);

	useEffect(() => {
		// IF COOKIE ALREADY SET
		if (ref.current && cookies[cookieName] !== undefined) {
			setOpen(false);
			listenTransitions(ref.current, () => {
				setAppear(false);
			});
		}
		//set window flag based on cookie choice
		window[gaDisableString] = cookies[cookieName] == 'true' ? true : false;
	}, [cookies]);

	useEffect(() => {
		setCookieConsentOpen(open);
	}, [open]);

	//removeCookie(cookieName, { path: '/' });
	const onAccept = (): void => {
		setCookie(cookieName, false, { path: '/' });
	};

	const onDecline = (): void => {
		setCookie(cookieName, true, { path: '/' });
	};

	return appear ? (
		<div className={cx(styles.root, open && styles.open, styles[theme], styles[pageType])} ref={ref}>
			<Body>
				Privacy and Cookies. This site uses cookies to offer a better browsing experience. &nbsp;
				<Link id="cookie-policy" url="/privacy-policy" target="_blank">
					Read More
				</Link>
			</Body>
			<div className={styles.buttons}>
				<BaseButton
					className={styles.decline}
					onClick={onDecline}
					size="small"
					buttonType="button"
					inverted
					link={{
						url: '',
						id: 'cookie-decline',
						target: '_modal',
						label: 'Decline'
					}}
				/>
				<BaseButton
					className={styles.accept}
					onClick={onAccept}
					size="small"
					buttonType="button"
					link={{
						url: '',
						id: 'cookie-accept',
						target: '_modal',
						label: 'Accept'
					}}
				/>
			</div>
		</div>
	) : (
		<></>
	);
};

export default CookieConsent;
