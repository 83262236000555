import { get } from 'lodash';
import React, { ReactElement, createContext, useState, useContext, useEffect } from 'react';

/////////////////////////////////////////////////////////////////////
// PAGE THEME CONTEXT
////////////////////////////////////////////////////////////////////
export type PageThemeType = string;
export type PageThemeContextType = {
	theme: PageThemeType;
	setTheme: (theme: PageThemeType) => void;
};

const PageThemeContext = createContext<PageThemeContextType>({ theme: 'light', setTheme: () => {} });
export const usePageTheme = (): PageThemeContextType => useContext(PageThemeContext);
export const PageThemeProvider = ({
	children,
	initialTheme
}: {
	children: ReactElement | ReactElement[];
	initialTheme: PageThemeType;
}): ReactElement => {
	const [theme, setTheme] = useState<string>(initialTheme);
	return <PageThemeContext.Provider value={{ theme, setTheme }}>{children}</PageThemeContext.Provider>;
};

/////////////////////////////////////////////////////////////////////
// CONTENT REFRESH
////////////////////////////////////////////////////////////////////
export type ContentRefreshType = {
	needsUpdate: boolean;
	setNeedsUpdate: (val: boolean) => void;
};

const ContentRefreshContext = createContext<ContentRefreshType>({ needsUpdate: false, setNeedsUpdate: () => {} });
export const useContentRefresh = (): ContentRefreshType => useContext(ContentRefreshContext);
export const ContentRefreshProvider = ({ children }: { children: ReactElement | ReactElement[] }): ReactElement => {
	const [needsUpdate, setNeedsUpdate] = useState<boolean>(false);
	return (
		<ContentRefreshContext.Provider value={{ needsUpdate, setNeedsUpdate }}>{children}</ContentRefreshContext.Provider>
	);
};

/////////////////////////////////////////////////////////////////////
// APP CONTEXT
////////////////////////////////////////////////////////////////////

export interface IAppContext {
	type: string;
}

export type AppContextType = {
	pageType: string;
	setPageType: (pageType: string) => void;
  isMobile: boolean;
	pageTitle: string;
	setPageTitle: (pageTitle: string) => void;
	location: string;
	setLocation: (location: string) => void;
  backUrl: string|null;
  setBackUrl: (url: string | null | undefined) => void;
	mode: 'day' | 'night' | 'yellow' | 'unset';
	setMode: (mode: 'day' | 'night' | 'yellow' | 'unset') => void;
	useFallback: boolean;
	setUseFallback: (useFallback: boolean) => void;
	loaded: boolean;
	setLoaded: (loaded: boolean) => void;
	cookieConsentOpen: boolean;
	setCookieConsentOpen: (cookieConsentOpen: boolean) => void;
};
const AppContext = createContext<AppContextType>({
	pageType: 'index',
	setPageType: () => {},
	pageTitle: 'Home',
	setPageTitle: () => {},
	location: '/',
	setLocation: () => {},
	mode: 'night',
	setMode: () => {},
  isMobile: false,
	useFallback: true,
	setUseFallback: () => {},
	loaded: true,
  backUrl: null,
  setBackUrl: () => {},
	setLoaded: () => {},
	cookieConsentOpen: false,
	setCookieConsentOpen: () => {}
});
export const useApp = (): AppContextType => useContext(AppContext);

const getSystemTheme = () => window.matchMedia('(prefers-color-scheme: dark)').matches ? 'night' : 'day'

export const useSystemMode = (): string => {
	const [theme, setTheme] = useState(
		getSystemTheme()
	)
	useEffect(() => {
		window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
			setTheme(e.matches ? 'night' : 'day')
		})
	}, [])
	return theme
}

export interface Props {
	children: ReactElement | ReactElement[];
}

export const AppProvider = ({ children }: Props): ReactElement => {
	const [pageType, setPageType] = useState<string>('index');
	const [pageTitle, setPageTitle] = useState<string>('Home');
	const [location, setLocation] = useState<string>('/');
	const [mode, setMode] = useState<'day' | 'night' | 'yellow' | 'unset'>('unset');
  const [backUrl, setBackUrl] = useState<string|null>(null)
	const [useFallback, setUseFallback] = useState<boolean>(false);
	const [loaded, setLoaded] = useState<boolean>(false);
	const [cookieConsentOpen, setCookieConsentOpen] = useState<boolean>(false);
  const [isMobile, setMobile] = useState<boolean>(false);

  useEffect(() => {
    if (window.matchMedia('screen and (max-width: 600pt)').matches) {
      setMobile(true)
    }
		window.matchMedia('screen and (max-width: 600pt').addEventListener('change', (e) => {
			setMobile(e.matches)
		})
  }, [])

	return (
		<AppContext.Provider
			value={{
				pageType,
				setPageType,
				pageTitle,
				setPageTitle,
				location,
				setLocation,
				mode,
				setMode,
        setBackUrl,
        backUrl,
        isMobile,
				useFallback,
				setUseFallback,
				loaded,
				setLoaded,
				cookieConsentOpen,
				setCookieConsentOpen
			}}
		>
			{children}
		</AppContext.Provider>
	);
};

export default AppContext;
