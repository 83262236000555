import React from 'react';

export function Instagram({ color = 'currentColor' }) {
    return (
        <svg 
            version="1.1"
            id="Lager_1"    
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 46"
            width="25pt"
            height="18pt"
        >
            <g transform="matrix(1,0,0,1,-661.13,-362.351)">
                <path fill={color} d="M661.15,373.67C661.52,371.72 661.87,369.77 662.93,368.03C665.35,364.08 669.14,362.56 673.5,362.46C679.45,362.31 685.41,362.32 691.37,362.46C695.41,362.56 699.01,363.89 701.47,367.39C703.05,369.65 703.64,372.23 703.68,374.9C703.77,380.62 703.81,386.35 703.67,392.07C703.56,396.68 701.96,400.61 697.65,402.95C695.66,404.03 693.47,404.5 691.24,404.53C685.36,404.6 679.48,404.66 673.6,404.52C669.19,404.42 665.36,402.93 662.91,398.94C661.85,397.21 661.5,395.25 661.13,393.3C661.15,386.76 661.15,380.22 661.15,373.67ZM682.48,366.5C679.56,366.5 676.64,366.44 673.72,366.51C669.16,366.62 666.36,368.75 665.53,372.68C665.35,373.51 665.22,374.37 665.22,375.22C665.19,380.75 665.12,386.28 665.23,391.8C665.34,397.3 668.12,400.24 673.43,400.44C679.42,400.66 685.42,400.64 691.42,400.44C695.84,400.29 698.47,398.17 699.31,394.35C699.46,393.67 699.6,392.97 699.61,392.27C699.64,385.97 699.99,379.66 699.42,373.37C699.08,369.64 696.59,367.21 692.87,366.7C689.41,366.23 685.94,366.57 682.48,366.5Z" style={{"fill-rule": "nonzero"}} />
            </g>
            <g transform="matrix(1,0,0,1,-661.13,-362.351)">
                <path fill={color} d="M693.46,383.56C693.44,389.5 688.51,394.38 682.46,394.45C676.39,394.52 671.35,389.51 671.37,383.42C671.39,377.43 676.5,372.41 682.48,372.53C688.63,372.64 693.48,377.52 693.46,383.56ZM689.37,383.51C689.38,379.73 686.26,376.63 682.44,376.61C678.64,376.59 675.48,379.7 675.47,383.47C675.46,387.25 678.58,390.35 682.4,390.37C686.2,390.39 689.36,387.28 689.37,383.51Z" style={{"fill-rule": "nonzero"}} />
            </g>
            <g transform="matrix(1,0,0,1,-661.13,-362.351)">
                <path fill={color} d="M693.76,369.64C695.22,369.62 696.46,370.83 696.46,372.28C696.45,373.66 695.26,374.85 693.85,374.89C692.39,374.93 691.12,373.74 691.1,372.3C691.06,370.85 692.26,369.66 693.76,369.64Z" style={{"fill-rule": "nonzero"}} />
            </g>
        </svg>
    )
}