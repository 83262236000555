import {
	PlaneGeometry,
	Mesh,
	Scene,
	RawShaderMaterial,
	Texture,
	Uniform,
	Vector2,
	Vector3,
	WebGLRenderer,
	Camera
} from 'three';

import frag from '../shaders/post.frag';
import vertexShader from '../shaders/base.vert';
import { RenderTarget } from '../utils/RenderTarget';
export class PostFXPass {
	public readonly scene: Scene;
	private material: RawShaderMaterial;
	private mesh: Mesh;
	private RT: RenderTarget;
	constructor(readonly resolution: Vector2, readonly scale: Number) {
		this.scene = new Scene();
		this.RT = new RenderTarget(resolution);
		const geometry = new PlaneGeometry(2, 2);

		this.material = new RawShaderMaterial({
			uniforms: {
				colorBuffer: new Uniform(Texture.DEFAULT_IMAGE),
				sLogo: new Uniform(Texture.DEFAULT_IMAGE),
				time: new Uniform(0.0),
				res: new Uniform(new Vector2(resolution.x, resolution.y)),
				brush: new Uniform(new Vector2(0)),
				scale: new Uniform(1),
				mode: new Uniform(1),
				logoPos: new Uniform(0)
			},
			vertexShader: vertexShader,
			fragmentShader: frag,
			depthTest: false,
			depthWrite: false,
			transparent: true
		});
		this.mesh = new Mesh(geometry, this.material);
		//this.mesh.frustumCulled = false; // Just here to silence a console error.
		this.scene.add(this.mesh);
	}

	public update(uniforms: any): void {
		for (const k in uniforms) {
			if (uniforms[k] !== undefined) {
				this.material.uniforms[k].value = uniforms[k];
			}
		}
	}

	public resize(res: Vector2): void {
		this.RT.resize(res);
		this.update({ res: new Vector2().copy(res) });
	}

	public render(renderer: WebGLRenderer, camera: Camera): Texture {
		const t = this.RT.set(renderer); // SET NEXT BUFFER
		renderer.render(this.scene, camera);
		return t;
	}
}
