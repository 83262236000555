import React, { ReactElement } from 'react';
import { AppProvider } from './src/context/AppContext';

export const wrapRootElement = ({ element, location }: { element: ReactElement; location: any }) => (
	<AppProvider>{element}</AppProvider>
);

/*
export const wrapPageElement = ({ element, props }: { element: ReactElement; props: any }) => {
	return <Layout {...props}>{element}</Layout>;
};
*/
export const onRouteUpdate = ({ location, prevLocation }: { location: any; prevLocation: any }) => {};

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
	return false;
};
