import {
	PlaneGeometry,
	Mesh,
	Scene,
	RawShaderMaterial,
	Texture,
	Uniform,
	Vector2,
	Vector3,
	HalfFloatType,
	WebGLRenderer,
	Camera
} from 'three';

import frag from '../shaders/ca.frag';
import vertexShader from '../shaders/base.vert';
export class CAPass {
	public readonly scene: Scene;
	private material: RawShaderMaterial;
	private mesh: Mesh;
	constructor() {
		this.scene = new Scene();
		const geometry = new PlaneGeometry(2, 2);

		this.material = new RawShaderMaterial({
			uniforms: {
				sTexture: new Uniform(Texture.DEFAULT_IMAGE),
				time: new Uniform(0.0),
				res: new Uniform(new Vector2(0))
			},
			vertexShader: vertexShader,
			fragmentShader: frag,
			depthTest: false,
			depthWrite: false,
			transparent: true
		});
		this.mesh = new Mesh(geometry, this.material);
		//this.mesh.frustumCulled = false; // Just here to silence a console error.
		this.scene.add(this.mesh);
	}

	public update(uniforms: any): void {
		for (const k in uniforms) {
			if (uniforms[k] !== undefined) {
				this.material.uniforms[k].value = uniforms[k];
			}
		}
	}

	public resize(res: Vector2): void {
		this.update({ res: new Vector2().copy(res) });
	}
}
