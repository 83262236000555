import React, { ReactElement, useEffect, useState } from 'react';
import { useApp, usePageTheme } from '@Src/context/AppContext';
import cx from 'classnames';
import * as styles from './index.module.scss';
export interface Props {
	visible?: boolean;
}

const LogoBanner = ({ visible = true }: Props): ReactElement => {
	const { theme: pageTheme } = usePageTheme();
	const [loaded, setLoaded] = useState(false);
	const { pageType } = useApp();
	useEffect(() => {
		const logo = new Image();
		logo.src = '/textures/tejp_brus_new.png';
		logo.onload = () => {
			setLoaded(true);
		};
		logo.complete && setLoaded(true);
	}, []);
	return (
		<div
			className={cx(
				styles.root,
				styles[pageType],
				styles[pageTheme],
				visible && styles.visible,
				loaded && styles.loaded
			)}
		></div>
	);
};

export default LogoBanner;
