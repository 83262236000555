import { PlaneGeometry, Mesh, Scene, RawShaderMaterial, Texture, Uniform, Vector2, WebGLRenderer, Camera } from 'three';

import frag from '../shaders/logo.frag';
import vertexShader from '../shaders/base.vert';
import { RenderTarget } from '../utils/RenderTarget';
export class LogoTexturePass {
	public readonly scene: Scene;
	private material: RawShaderMaterial;
	private mesh: Mesh;
	private RT: RenderTarget;
	constructor(readonly resolution: Vector2) {
		this.scene = new Scene();

		const geometry = new PlaneGeometry(2, 2);
		this.RT = new RenderTarget(resolution);
		this.material = new RawShaderMaterial({
			uniforms: {
				sTexture: new Uniform(Texture.DEFAULT_IMAGE),
				time: new Uniform(0.0),
				res: new Uniform(new Vector2(resolution.x, resolution.y)),
				logoPos: new Uniform(new Vector2(0)),
				outline: new Uniform(0.0)
			},
			vertexShader: vertexShader,
			fragmentShader: frag,
			depthTest: false,
			depthWrite: false,
			transparent: true
		});
		this.mesh = new Mesh(geometry, this.material);
		this.scene.add(this.mesh);
	}

	public update(uniforms: any): void {
		for (const k in uniforms) {
			if (uniforms[k] !== undefined) {
				this.material.uniforms[k].value = uniforms[k];
			}
		}
	}

	public resize(res: Vector2): void {
		this.RT.resize(res);
		this.update({ res: this.RT.res });
	}

	public set(renderer: WebGLRenderer): Texture {
		return this.RT.set(renderer);
	}

	public get(): Texture {
		return this.RT.get();
	}

	public render(renderer: WebGLRenderer, camera: Camera): Texture {
		const t = this.RT.set(renderer);
		renderer.render(this.scene, camera);
		return t;
	}
}
