import React, { ReactElement } from 'react';
import { useApp, usePageTheme } from '@Src/context/AppContext';
import cx from 'classnames';
import { Body, Heading01, UiHeading01 } from '../../Text';
import Link from '../../ui/Link';
import * as styles from './index.module.scss';
export interface Props {
	visible: boolean;
	pageTitle: string;
}

const TitleBar = ({ visible, pageTitle }: Props): ReactElement => {
	const { theme: pageTheme } = usePageTheme();
	const { pageType, backUrl } = useApp();
	return (
		<div className={cx(styles[pageType], styles.root, styles[pageTheme], visible && styles.visible)}>
			<div className={styles.home}>
      {backUrl ?
          <Link id="back" url={backUrl}>
					  <Body className={cx(styles.close)} theme={pageTheme} text={'<'}></Body>
          </Link>
        :
				<Link id="home" url="/">
					<Body className={cx(styles.close)} theme={pageTheme} text={'X'}></Body>
				</Link>
}
			</div>
			<Link id="signup" url="/signup" label="" className={styles.signup}>
				<UiHeading01 className={cx(styles.title)} theme={pageTheme} text={'Get Started'}></UiHeading01>
			</Link>
			<Heading01 theme={pageTheme} text={pageTitle} className={styles.pageTitle} />
		</div>
	);
};

export default TitleBar;
