import React, { ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import TransitionLink from 'gatsby-plugin-transition-link';
import { ILink } from '@Src/types/ContentfulTypes';
import { CustomWindow } from '@Src/types/CustomWindow';
import { useWindowResize } from '@Src/utils/useWindowResize';

declare let window: CustomWindow;
export interface Props extends ILink {
	/**
	 * Children to render
	 */
	children: ReactNode | string;
	/**
	 * lassName
	 */
	className?: string;
	/**
	 * Active className
	 */
	activeClassName?: string;
	/**
	 * partiallyActive className
	 */
	partiallyActive?: boolean;
	/**
	 * Props to handle if the link are internal, to handle state. Check gatsby link for docs.
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getLinkProps?: any;

	useTransition?: boolean;

	onTrigger?: () => void;
	triggerDelay?: number;
}

interface ITransitionProps {
	children: ReactNode;
	node: HTMLElement;
	e: MouseEvent;
	exit: any;
	entry: any;
	url: string;
}

const Link = ({
	label,
	children,
	url,
	target = '_self',
	className = '',
	activeClassName = '',
	getLinkProps,
	triggerDelay = 0,
	onTrigger,
	useTransition = true
}: Props): ReactElement => {
	const internal = /^\/(?!\/)/.test(url);
	const windowSize = useWindowResize();
	const [isDesktopLayout, setIsDesktopLayout] = useState(true);
	useEffect(() => {
		if (windowSize.width < 992) setIsDesktopLayout(false);
		else setIsDesktopLayout(true);
	}, [windowSize]);

	if (internal && target !== '_blank') {
		return (
			<TransitionLink
				to={url}
				activeClassName={activeClassName}
				className={className}
				getProps={getLinkProps}
				onClick={() => {
					onTrigger && onTrigger();
				}}
				exit={{
					length: isDesktopLayout ? 0.5 : 0.5,
					delay: isDesktopLayout ? 0 : triggerDelay
				}}
				entry={{
					delay: isDesktopLayout ? 0 : triggerDelay,
					length: 0.5
				}}
			>
				{children}
			</TransitionLink>
		);
	}
	return (
		<a href={url} className={className} target={target}>
			{children}
			{label}
		</a>
	);
};

export default Link;
