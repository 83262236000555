import React, { ReactElement } from 'react';
import { useApp, usePageTheme } from '@Src/context/AppContext';
import { ILink } from '@Types/ContentfulTypes';
import cx from 'classnames';
import LinkList from '@UI/LinkList';
import Timer from '@Components/Timer';
import * as styles from './index.module.scss';

export interface Props {
	/**
	 * Footer links
	 */
	links?: Array<ILink>;
	visible: boolean;
}

const Footer = ({ links, visible }: Props): ReactElement => {
	const { theme: pageTheme } = usePageTheme();
	const { pageType } = useApp(); 
	return (
		<footer className={cx(styles.root, styles[pageTheme], styles[pageType], visible && styles.visible)}>
			<nav className={styles.nav}>
				{links && <LinkList items={links} theme={pageTheme} size="small" />}
				
			</nav>
			<div className={styles.clock}>
				<Timer />
			</div>
		</footer>
	);
};

export default Footer;
