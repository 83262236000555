import {
	PlaneGeometry,
	Mesh,
	RawShaderMaterial,
	Scene,
	Texture,
	Uniform,
	Vector2,
	Vector3,
	WebGLRenderer,
	Camera,
	HalfFloatType,
	FloatType,
	RGBAFormat,
	ClampToEdgeWrapping,
	RepeatWrapping,
	LinearFilter,
	NearestFilter
} from 'three';
import reactionDiffusionFrag from '../shaders/reaction_diffusion.frag';
import vertexShader from '../shaders/base.vert';
import { RenderTarget } from '../utils/RenderTarget';
export class ReactionDiffusionPass {
	public readonly scene: Scene;
	private material: RawShaderMaterial;
	private mesh: Mesh;
	private RT: RenderTarget;
	private NUM_PASSES: number = 2;
	constructor(readonly resolution: Vector2, readonly seedRandom: boolean) {
		this.scene = new Scene();
		this.RT = new RenderTarget(
			new Vector2(resolution.x, resolution.y),
			this.NUM_PASSES,
			HalfFloatType,
			RGBAFormat,
			ClampToEdgeWrapping,
			ClampToEdgeWrapping
		);
		const geometry = new PlaneGeometry(2, 2);
		this.material = new RawShaderMaterial({
			uniforms: {
				time: new Uniform(0.0),
				sTrail: new Uniform(Texture.DEFAULT_IMAGE),
				sForces: new Uniform(Texture.DEFAULT_IMAGE),
				flow: new Uniform(1.003),
				clear: new Uniform(0),
				k: new Uniform(0.022),
				//k: new Uniform(0.022),
				//feed: new Uniform(0.0032514826761003038),
				feed: new Uniform(0.0032514826761003038),
				dA: new Uniform(1),
				dB: new Uniform(0.5),
				res: new Uniform(new Vector2(resolution.x, resolution.y)),
				brushSize: new Uniform(0.05 * Math.sqrt(resolution.x * resolution.x + resolution.y * resolution.y)),
				brush: new Uniform(new Vector3(0))
			},
			vertexShader: vertexShader,
			fragmentShader: reactionDiffusionFrag,
			depthTest: false,
			depthWrite: false
		});

		this.mesh = new Mesh(geometry, this.material);
		this.mesh.frustumCulled = false; // Just here to silence a console error.
		this.scene.add(this.mesh);
	}

	public update(uniforms: any): void {
		for (const k in uniforms) {
			if (uniforms[k] !== undefined) {
				this.material.uniforms[k].value = uniforms[k];
			}
		}
	}

	public resize(res: Vector2): void {
		this.RT.resize(res);
		this.update({ res: this.RT.res, brushSize: 0.05 * Math.sqrt(res.x * res.x + res.y * res.y) });
	}

	public set(renderer: WebGLRenderer): Texture {
		return this.RT.set(renderer);
	}

	public render(renderer: WebGLRenderer, camera: Camera): Texture {
		let t: Texture = this.RT.get();

		for (let i = 0; i < this.NUM_PASSES; i++) {
			t = this.RT.set(renderer); // SET NEXT BUFFER
			//t.minFilter = NearestFilter;
			//t.magFilter = NearestFilter;
			renderer.render(this.scene, camera);
			this.material.uniforms.sTrail.value = t;
		}
		return t;
	}
}
