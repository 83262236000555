import { useState, useEffect, useRef, MutableRefObject, useLayoutEffect } from 'react';
export interface WindowSize {
	width: number;
	height: number;
	dpi: number;
	breakpoint: number;
}
// Hook
export const useWindowResize = (): WindowSize => {
	const [windowSize, setWindowSize] = useState<WindowSize>({
		width: 0,
		height: 0,
		dpi: 1,
		breakpoint: 0
	});
	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
				dpi: 1,
				breakpoint: window.innerWidth < 992 ? 0 : 1
			});
		}
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);
	return windowSize;
};
