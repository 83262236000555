import React, { ReactElement, useEffect, useState } from 'react';
import { useApp, usePageTheme } from '@Src/context/AppContext';
import cx from 'classnames';
import * as styles from './index.module.scss';

export interface Props {
	children: ReactElement | Array<ReactElement>;
	pageReady: boolean;
}

const Root = ({ children, pageReady }: Props): ReactElement => {
	const { theme: pageTheme } = usePageTheme();
	const [height, setHeight] = useState(0);
	const { mode, setMode } = useApp();

	const [windowResizing, setWindowResizing] = useState<boolean>(false);
	useEffect(() => {
		let timeout: any;

		const handleResize = () => {
			clearTimeout(timeout);
			setWindowResizing(true);
			setHeight(window.innerHeight);
			timeout = setTimeout(() => {
				setWindowResizing(false);
			}, 200);
		};
		setHeight(window.innerHeight);
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);
	useEffect(() => {
		function logKey(e: KeyboardEvent) {
			if ((e.metaKey || e.ctrlKey) && e.key == 'ArrowDown') setMode('night');
			else if ((e.metaKey || e.ctrlKey) && e.key == 'ArrowUp') setMode('day');
			else if ((e.metaKey || e.ctrlKey) && e.key == 'ArrowRight') setMode('yellow');
		}
		document.addEventListener('keydown', logKey);
		return () => document.removeEventListener('keydown', logKey);
	}, []);
	return (
		<div
			style={{ minHeight: height }}
			id={styles.root}
			className={cx(
				pageReady && styles.pageReady,
				pageTheme && styles[pageTheme],
				(windowResizing && styles.windowResizing) || ''
			)}
		>
			{children}
		</div>
	);
};

export default Root;
