import React, { ReactElement, useEffect, useState } from 'react';
import moment from 'moment';
import { globalHistory } from '@reach/router';

import { useApp, usePageTheme } from '@Src/context/AppContext';
import { Body } from '@Components/Text';

const Timer = (): ReactElement => {
	const [currentTime, setCurrentTime] = useState(moment());
	const { mode, setMode } = useApp();
	const [defaultMode, setDefaultMode] = useState(mode);
	const [autoSwitch, setAutoSwitch] = useState(true);
	useEffect(() => {
		const interval = setInterval(() => {
			const t = moment();
			const h = parseInt(t.format('HH'));
		  setMode('night');
			setCurrentTime(t);
		}, 1000);
		return () => clearInterval(interval);
	}, [autoSwitch]);

	useEffect(() => {
		if (mode !== defaultMode) {
			setAutoSwitch(false);
		}
	}, [mode]);

	useEffect(() => {
		setMode(defaultMode);
	}, [defaultMode]);

  useEffect(() => {
    
    return globalHistory.listen(({ action, location } : { action: string, location: Location}) => {
      console.log("globalHistory.listen triggered ", location)
      console.log("action", action)
      if (action === 'push' || true) {
        console.log("shall we invoke dataLayer?")
        let dataLayer = []
        if (typeof window !== 'undefined') {
          if (window.dataLayer instanceof Array) {
            dataLayer = window.dataLayer
          }
        }
        if (dataLayer instanceof Array) {
          console.log("dataLayer exists")
          // @ts-ignore
          if (dataLayer instanceof Array) {
            console.log("dataLayer is array")
            console.log("Pushing to dataLayer intercom.reload")
            // @ts-ignore
            dataLayer.push({
              event: 'intercom.reload',
              theme: defaultMode,
              isMobile: window.matchMedia('screen and (max-width: 720pt)').matches
            })
            console.log("Pushed to dataLayer intercom.reload")
          } else {
            console.log("dataLayer is not an array")
          }
        } else {
          console.log("dataLayer is not defined")
        }
      }
    })
  }, []) 

	//INIT DEFAULT MODE
	useEffect(() => {
		const t = moment();
		const h = parseInt(t.format('HH'));
    let pageMode = 'night';

    setDefaultMode(pageMode)
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('pageMode', pageMode)
    }
    
		//setDefaultMode('night');
		setCurrentTime(t);
    setTimeout(() => {
      if (typeof dataLayer !== 'undefined') {
        // @ts-ignore
        if (dataLayer instanceof Array) {
          // @ts-ignore
          dataLayer.push({
            event: 'intercom.reload',
            theme: localStorage.getItem('pageMode'),
            isMobile: window.matchMedia('screen and (max-width: 720pt)').matches
          })
        }
      }
    }, 1000)
  }, []);

	const { theme: pageTheme } = usePageTheme();
	return <Body theme={pageTheme} text={currentTime.format('HH:mm MMMM DD YYYY')} />;
};

export default Timer;
