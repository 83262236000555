import React, { ReactElement, ReactNode, useState, useEffect } from 'react';
import cx from 'classnames';
import '@GraphQL/fragments';
import * as styles from './index.module.scss';

export interface Props {
	/**
	 * Children to render
	 */
	children: ReactNode;
	/**
	 * Classname to be merged
	 */
	className?: string;
	/**
	 * If active ie if on homepage
	 */
	active?: boolean;

	visible: boolean;
}

const ArtArea = ({ children, active, visible, className = '' }: Props): ReactElement => {
	const [state, setState] = useState(active);
	useEffect(() => {
		setState(active);
	}, [active]);
	return (
		<div className={cx(styles.root, active && styles.active, visible && styles.visible, className)}>
			{typeof window === 'undefined' ? null : children}
		</div>
	);
};
export default ArtArea;
