import React, { ReactElement } from 'react';
import cx from 'classnames';
import { ILink } from '@Types/ContentfulTypes';
import Link from '@UI/Link';
import { Facebook, Instagram } from '../../icons';
import { UiHeading02 } from '@Components/Text';
import * as styles from './index.module.scss';
export interface Props {
	/**
	 * Children to be rendered
	 */
	items: ILink[];
	/**
	 * Size decides to render label as SmallHeading01 or SmallHeading02
	 */
	size?: 'default' | 'small';
	/**
	 * Theme settings in current block
	 */
	theme?: string;
	/**
	 * Custom className
	 */
	className?: string;
}

const LinksList = ({ items, size, theme, className = '' }: Props): ReactElement => (
	<ul className={cx(styles.root, className)}>
		{/* eslint-disable react/no-array-index-key */}
		{items.map(
			(item: ILink, index: number): ReactElement => (
				<li key={index}>
					<Link
						activeClassName={styles.active}
						className={cx(styles.root, className, theme && styles[theme])}
						id={item?.id}
						url={item?.url}
						target={item?.url.indexOf('http') !== 0 ?? item?.target}
					>
						<UiHeading02 className={cx(size && styles[size])} theme={theme} text={item?.label} />
					</Link>
				</li>
			)
		)}
		<li>
			<Link target="_blank" url={`https://www.facebook.com/aloadedmusic`}>
				<Facebook color={theme === 'dark' ? 'white' : 'dark'} />
			</Link>
		</li>
		<li>
			<Link target="_blank" url={`https://www.instagram.com/aloaded`}>
				<Instagram color={theme === 'dark' ? 'white' : 'dark'} />
			</Link>
		</li>
	</ul>
);

export default LinksList;
