import React from 'react';

export function Facebook({ color = 'currentColor', size = 28 }) {
    return (
        <svg 
            version="1.1"
            id="Lager_1"    
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 46"
            width="18pt"
            height="18pt"
        >
            <g transform="">
                <g transform="matrix(1,0,0,1,-657.825,-360.14)">
                    <path fill={color} d="M689.96,369.53L693.73,369.53L693.73,361.33L688.06,361.32C680.02,361.32 676.21,366.1 676.21,372.91L676.21,379.36L669.92,379.36L669.92,387.15L676.21,387.15L676.21,406.96L685.65,406.96L685.65,387.15L691.94,387.15L693.73,379.36L685.65,379.36L685.65,374.04C685.65,371.88 686.84,369.53 689.96,369.53Z" style={{'fill-rule': 'nonzero'}} />
                </g>
            </g>
        </svg>
    )
}